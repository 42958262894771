// import * as firebase from 'firebase'

// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/firestore'
import 'firebase/auth'

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: 'AIzaSyBj7ZrWuRONDNOtTBB3wFbBPpvQkvKc3MM',
  authDomain: 'gp2-backend-123.firebaseapp.com',
  databaseURL: 'https://gp2-backend-123.firebaseio.com',
  projectId: 'gp2-backend-123',
  storageBucket: 'gp2-backend-123.appspot.com',
  messagingSenderId: '194367037004',
  appId: '1:194367037004:web:ef8fc022110e789e069ee9',
}
firebase.initializeApp(firebaseConfig)

require('firebase/auth')

// utils
const db = firebase.firestore()
const auth = firebase.auth()

// FB Storage (30 Apr)
const firebaseStorage = firebase.storage()

// Google Authenication (10 Oct)
// const googleProvider = new firebase.auth.GoogleAuthProvider()

// collection references
const goalsCollection = db.collection('goals')
const tasksCollection = db.collection('tasks')
const commentsCollection = db.collection('comments')
const documentsCollection = db.collection('documents')
const sys_usersCollection = db.collection('sys_users')

// export utils/refs
export {
  db,
  auth,
  goalsCollection,
  tasksCollection,
  commentsCollection,
  documentsCollection,
  sys_usersCollection,
  firebaseStorage,
  // googleProvider,
}
